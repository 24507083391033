import React from 'react';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';

const theme = createMuiTheme({
  // For Underline Color After Click
  palette: {
    primary: { main: "rgba(255, 255, 255, 1)" },
  },
  // For Underline Hover Color
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #ffffff !important',
        },
        '&:after': {
          borderBottom: '2px solid #ffffff !important',
        },
      },
      root: { // Name of the rule
        color: 'white', // Some CSS
      },
    }
  }
});
export default class AppNumberField extends React.Component {

  /**
   * Get class name
   *
   * @returns {string}
   */
  getClassName() {
    let classNames = ['input-group'];
    if (this.props.fullWidth) {
      classNames.push('fulled');
    }

    return classNames.join(' ');
  }

  /**
   * Handle input change
   *
   * @param e
   */
  handleInputChange(e) {
    this.props.handler.handleInputChange(this.props.name, e.formattedValue);
  }

  getValidationError() {

    let messages = [];

    this.props.handler.getErrors().filter(x => x.field == this.props.name).forEach(
      error => messages.push(error.message)
    )

    if (messages.length == 0) {
      return null;
    }
    // if (this.props.value.length != 0 ) {
    //   return null;
    // }

    return <div className="validation-message" key={this.props.name + '-error'}>{messages.join("<br/>")}</div>;
  }


  /**
   * Render input
   *
   * @returns {XML}
   */
  render() {
    let className = this.getClassName();
    let validationError = this.getValidationError();
    return (
      <div className={className} id={validationError ? "standard-error" : ''}>
        <MuiThemeProvider theme={theme}>
          <NumberFormat
           margin="normal"
            error={validationError ? true : false}
            label={this.props.label}
            placeholder={this.props.placeholder}
            type={this.props.type}
            name={this.props.name}
            format={this.props.format}
            mask={this.props.mask}
            style={{ width: '100%' }}
            customInput={TextField}
            value={this.props.value}
            onValueChange={this.handleInputChange.bind(this)}
          />
        </MuiThemeProvider>
        {validationError}
      </div>
    );
  }
}
